<template>
  <div
      class="bg-white"
      :style="{ backgroundImage: 'url(' + backgroundImageSrc + ')' }"
  >
    <div
        class="flex flex-row w-full h-140 pt-34 px-40"
        :class="{ 'title-container': backgroundImageSrc }"
    >
      <slot></slot>
      <slot name="description"></slot>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    backgroundImageSrc: String,
  },
};
</script>


<style lang="scss" scoped>
.title-container {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
