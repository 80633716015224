<template>
  <div class="main-container">
    <div
        class="bg-white h-230 w-333 tablet-landscape:w-302 mr-19 mt-19 cursor-pointer
        tablet-landscape:mt-0 flex justify-center flex-col content-center items-center"
        @click="openTool(link)"
    >
      <img
          class="w-132 h-120 tablet-landscape:w-120"
          :src="imageSrc">
      <p class="mt-13 text-lg text-center font-sans-bold text-charcoal-grey">
        {{ title }}
      </p>
      <p
          v-if="showMoreText"
          class="h-26 mt-4 text-md text-center text-faded-red"
      >
        {{ subtitle }}
      </p>
    </div>
    <div
        v-if="showMoreText"
        class="main-text h-auto w-281 mt-17 mb-9 text-charcoal-grey leading-29"
    >
      <p class="main-text h-auto mt-17 mb-9 text-charcoal-grey leading-29">
        {{ mainText }}
      </p>
      <p class="sub-text h-76 leading-43 mt-4 text-cool-grey text-sm">
        {{ subText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: String,
    link: String,
    showMoreText: Boolean,
    title: String,
    subtitle: String,
    mainText: String,
    subText: String,
  },
  methods: {
    openTool(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
