<template>
  <div class="bg-pure-white">
    <ViewHeadSection :background-image-src="'/images/placeholders/tools-image.png'">
      <p class="w-1/2 flex-no-shrink font-sans-bold text-xl text-pure-white">
        {{ $t('tools.top_left_title_text') }}
      </p>
      <p
          slot="description"
          class="flex-grow text-lg text-pure-white pl-40"
      >
        <span class="font-sans-bold">SWISS KRONO</span> {{ $t('tools.top_right_title_text') }}
      </p>
    </ViewHeadSection>
    <div class="flex">
      <div class="pl-39 pt-32 flex flex-wrap">
        <!--First Tool component-->
        <ToolCard
            :image-src="'/images/tools/u-value.svg'"
            :link="`https://service.swisskrono.de/api/v1/tools/uwert?lang=${$i18n.locale}`"
            :show-more-text="true"
            :title="$t('tools.first_tool_title_text')"
            :subtitle="$t('tools.first_tool_subtitle_text')"
            :main-text="$t('tools.first_tool_main_text')"
            :sub-text="$t('tools.first_tool_sub_text')"
        />
        <!--Second Tool component-->
        <ToolCard
            :image-src="'/images/tools/condensation.svg'"
            :link="`https://service.swisskrono.de/api/v1/tools/tauwasser?lang=${$i18n.locale}`"
            :show-more-text="true"
            :title="$t('tools.second_tool_title_text')"
            :subtitle="$t('tools.second_tool_subtitle_text')"
            :main-text="$t('tools.second_tool_main_text')"
            :sub-text="$t('tools.second_tool_sub_text')"
        />
        <!--Third Tool component-->
        <ToolCard
            :image-src="'/images/tools/structural-analysis.svg'"
            :link="`https://service.swisskrono.de/api/v1/tools/statiktool?lang=${$i18n.locale}`"
            :show-more-text="true"
            :title="$t('tools.third_tool_title_text')"
            :subtitle="$t('tools.third_tool_subtitle_text')"
            :main-text="$t('tools.third_tool_main_text')"
            :sub-text="$t('tools.third_tool_sub_text')"
        />
      </div>
    </div>
  </div>
</template>


<script>
import ViewHeadSection from '@/components/view/ViewHeadSection';
import ToolCard from '@/components/ToolCard';


export default {
  components: {
    ToolCard,
    ViewHeadSection,
  },
};
</script>


<style lang="scss" scoped>
  .title-container {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .title-container-image {
    background-image: url('/images/placeholders/tools-image.png');
  }
</style>
